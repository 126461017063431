import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../services/snackMessage.service';
import { UtilsService } from '../../services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-card-horizontal',
  templateUrl: './card-horizontal.component.html',
  styleUrls: ['./card-horizontal.component.scss']
})
export class CardHorizontalComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() context?: string;
  @Input() _data: any;

  @Output() moreButtonEvent = new EventEmitter<{ id: string; event: object }>();

  className = 'app-card-horizontal';
  labels: CommonLabels = commonLabels;

  purchased: boolean = false;
  mostPurchased: boolean = false;
  special: boolean = false;
  featured: boolean = false;
  menuState: string = '';
  fakeTitle: string = '';
  fakeDescription: string = '';
  shortDescriptionTooltip: string;
  constructor(
    private snackMessageService: SnackMessageService,
    private categoriesStoreService: CategoriesStoreService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.categoriesStoreService.getStateMenu().subscribe(
      (state) => {
        this.menuState = state;
        this.shortDescriptionTooltip = this._data.shortDescription.replace(/<[^>]*>/g, '');
        if (this.width < 1279) {
          this.prepareData1024(this._data);
        }
        if (this.width >= 1279 && this.width < 1439) {
          this.prepareData1280(this._data);
        }
        if (this.width >= 1439 && this.width < 1679) {
          this.prepareData1440(this._data);
        }
        if (this.width >= 1679 && this.width < 1799) {
          this.prepareData1680(this._data);
        }
        if (this.width >= 1799 && this.width < 1900) {
          this.prepareData1800(this._data);
        }
        if (this.width >= 1900 && this.width < 2000) {
          this.prepareData1900(this._data);
        }
      },
      (error) => {
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    //console.log(this.width);

    this._data.categories.forEach((category) => {
      if (this.context !== '_home') {
        if (category === this.categoryPurchasedId) {
          this.purchased = true;
        }
        if (category === this.categoryMostPurchasedId) {
          this.mostPurchased = true;
        }
        if (category === this.categorySpecialId) {
          this.special = true;
        }
        if (category === this.categoryFeaturedId) {
          this.featured = true;
        }
      }
    });
  }

  prepareData1024(data): void {
    if (data.name.length > 45 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 45) + ' ...';
    } else if (data.name.length > 45 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 45) + ' ...';
    }
    if (data.shortDescription.length > 120 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 120) + ' ...';
    } else if (data.shortDescription.length > 120 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 120) + ' ...';
    }
  }
  prepareData1280(data): void {
    if (data.name.length > 45 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 45) + ' ...';
    } else if (data.name.length > 61 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 61) + ' ...';
    }
    if (data.shortDescription.length > 115 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 115) + ' ...';
    } else if (data.shortDescription.length > 145 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 145) + ' ...';
    }
  }
  prepareData1440(data): void {
    if (data.name.length > 62 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 62) + ' ...';
    } else if (data.name.length > 76 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 76) + ' ...';
    }
    if (data.shortDescription.length > 130 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 130) + ' ...';
    } else if (data.shortDescription.length > 170 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 170) + ' ...';
    }
  }
  prepareData1680(data): void {
    if (data.name.length > 80 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 80) + ' ...';
    } else if (data.name.length > 95 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 95) + ' ...';
    }
    if (data.shortDescription.length > 184 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 184) + ' ...';
    } else if (data.shortDescription.length > 206 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 206) + ' ...';
    }
  }
  prepareData1800(data): void {
    if (data.name.length > 55 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 55) + ' ...';
    } else if (data.name.length > 65 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 65) + ' ...';
    }
    if (data.shortDescription.length > 146 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 146) + ' ...';
    } else if (data.shortDescription.length > 160 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 160) + ' ...';
    }
  }
  prepareData1900(data): void {
    if (data.name.length > 61 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 62) + ' ...';
    } else if (data.name.length > 78 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 78) + ' ...';
    }
    if (data.shortDescription.length > 160 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 160) + ' ...';
    } else if (data.shortDescription.length > 180 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 180) + ' ...';
    }
  }

  clickButtonMore(id: string, event: object): void {
    this.moreButtonEvent.emit({ id, event });
  }
  ngOnDestroy() {}
}
