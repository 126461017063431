import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ApplicationModel, BannerModel, CommonLabels } from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ModalMessageComponent } from '../../../../common/components/modal-message/modal-message.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { ApplicationsService } from '../../../../common/services/applications.service';
import { SortCriteria } from 'app/common/utils/sort-by.pipe';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { AuthService } from '../../../../common/services/auth.service';
import { UtilsService } from '../../../../common/services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-home-public',
  templateUrl: './home-public.component.html',
  styleUrls: ['./home-public.component.scss']
})
export class HomePublicComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() openModalContactEmitter: EventEmitter<any> = new EventEmitter();
  theme = 'marketplace-default';
  className = 'app-home-public';
  isDesktop: boolean = false;
  width: number = 0;
  labels: CommonLabels = commonLabels;
  isLoading = true;
  showOverlay = true;
  // Mocks
  dataBanner: BannerModel = {
    title1: 'Already a member? Log in to access your account.',
    title2: 'New here? Contact us!!',
    text: 'Contact our sales team to start deploying your applications in the edge',
    buttonId: 'button-contact-us',
    button: 'Contact us',
    srcImg: 'assets/images/banners/banner.svg',
    srcImgMobile: 'assets/images/banners/banner.svg'
  };
  categoryFeaturedId: string; //Featured
  categorySpecialId: string; //Barbara Basics
  categoryPurchasedId: string; //Already in library
  categoryMostPurchasedId: string; //Most used
  dataApplications: ApplicationModel[] = [];
  showText: boolean = true;
  menuState: string;
  public criteria: SortCriteria;

  constructor(
    private readonly resizeWindowService: ResizeWindowService,
    public matDialog: MatDialog,
    private authService: AuthService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private applicationsService: ApplicationsService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    //subscribe to the window resize event
    this.isLoading = true;
    this.categoriesStoreService.setStateMenu('close');
    this.resizeWindowService.isDesktop$.subscribe(
      (value: boolean) => {
        this.isDesktop = value;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getStateMenu().subscribe(
      (state) => {
        this.menuState = state;
        if (this.width === 1024 && this.menuState === 'close') {
          this.showText = true;
        }
        if (this.width === 1024 && this.menuState === 'open') {
          this.showText = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.resizeWindowService.width$.subscribe(
      (value: number) => {
        this.width = value;
        this.isLoading = true;
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.width = value;
            this.isLoading = false;
          },
          500,
          'loadingHomePublic'
        );
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );

    this.applicationsService.getAllApplicationsP().subscribe(
      (data) => {
        console.log('data', data);
        this.dataApplications = data.sort((a, b) => {
          if (a.order > b.order) {
            return -1;
          }
          if (a.order < b.order) {
            return 1;
          }
          return 0;
        });

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.goTop();
  }

  ngAfterViewInit(): void {
    this.goTop();
  }

  goTop() {
    //Para que el scroll esté al inicio de la página
    document.getElementsByClassName('main-layout__content')[0].scrollTo(0, 0);
  }

  openModalContact($event) {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-grey',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: $event,
        type: 'contact-form-public',
        imgHeader: 'assets/images/logos/market_avatar.svg',
        title: 'Contact us',
        linkHeader: ' login.',
        textHeader1: 'You need to be a Barbara user to access the Marketplace, please ',
        textHeader2:
          'If you are not a user yet and you are interested in joining the Barbara platform, please fill out the form below and we will get back to you as soon as possible',
        terms: '',
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action !== 'close') {
        this.authService.sendFormContactUs(result);
      }
    });
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy() {}
}
