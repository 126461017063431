import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from '../common/shared-common.module';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MainLayoutComponent } from './components/private/main-layout/main-layout.component';
import { HeaderComponent } from './components/private/header/header.component';
import { MenuComponent } from './components/private/menu/menu.component';
import { HomeComponent } from './components/private/home/home.component';

import { MainLayoutPublicComponent } from './components/public/main-layout-public/main-layout-public.component';
import { HeaderPublicComponent } from './components/public/header-public/header-public.component';
import { MenuPublicComponent } from './components/public/menu-public/menu-public.component';
import { HomePublicComponent } from './components/public/home-public/home-public.component';

const routes: Routes = [
  {
    path: '_home',
    component: HomePublicComponent
  },
  {
    path: 'home',
    component: HomeComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedCommonModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule
  ],
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    MenuComponent,
    HomeComponent,
    MainLayoutPublicComponent,
    HeaderPublicComponent,
    MenuPublicComponent,
    HomePublicComponent
  ],
  exports: [
    MainLayoutComponent,
    HeaderComponent,
    MenuComponent,
    HomeComponent,
    MainLayoutPublicComponent,
    HeaderPublicComponent,
    MenuPublicComponent,
    HomePublicComponent
  ]
})
export class LayoutModule {}
