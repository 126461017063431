export const navigationPublic: any[] = [
  {
    id: '_home',
    title: 'Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/_home'
  }
];
export const navigationUser: any[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/home'
  }
];
export const navigationAdmin: any[] = [
  {
    id: 'home',
    title: 'Home Private',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/home'
  },
  {
    id: '_home',
    title: 'Home Public',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/_home'
  },
  {
    id: 'categories',
    title: 'Categories',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'category',
    url: 'admin/categories-list'
  },
  {
    id: 'applications',
    title: 'Applications',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'apps',
    url: 'admin/applications-list'
  }
];
