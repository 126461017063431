<div class="{{ theme }} {{ className }}" id="home-public">
  <div *ngIf="isLoading" class="{{ theme }} spinner">
    <mat-spinner color="purple" diameter="150" strokeWidth="10" class="{{ theme }}"></mat-spinner>
  </div>
  <ng-container *ngIf="dataBanner && !isLoading">
    <div class="{{ theme }} {{ className }}__banner">
      <app-banner
        [theme]="theme"
        [isDesktop]="isDesktop"
        [width]="width"
        (contactUsButtonEvent)="openModalContact($event)"
        [_data]="dataBanner"></app-banner>
    </div>
  </ng-container>

  <ng-container *ngIf="dataApplications !== undefined && !isLoading">
    <div class="{{ theme }} {{ className }}__category">
      <ul class="{{ theme }} {{ className }}__category-list" *ngIf="dataApplications.length > 0">
        <ng-container *ngFor="let app of dataApplications; trackBy: identify; let i = index">
          <li class="{{ theme }} {{ className }}__category-list-item" *ngIf="app.enabled">
            <app-card-vertical
              [theme]="theme"
              [isDesktop]="isDesktop"
              [width]="width"
              [categoryFeaturedId]="categoryFeaturedId"
              [categorySpecialId]="categorySpecialId"
              [categoryPurchasedId]="categoryPurchasedId"
              [categoryMostPurchasedId]="categoryMostPurchasedId"
              [showText]="showText"
              context="_home"
              [_data]="app"></app-card-vertical>
            <div
              *ngIf="showOverlay"
              id="card-vertical-overlay-{{ utilsService.removeSpacesAndInsertScript(app.name)}}"
              (click)="openModalContact($event)"
              class="{{ theme }} {{ className }}__overlay"></div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>
