import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationModel, CategoryModel, CommonLabels } from 'app/common/models/common.model';

import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesStoreService } from '../../../common/services/category-observable.service';
import { MatTabGroup } from '@angular/material/tabs';
import { StorageService } from '../../../common/services/storage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss']
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-application-detail';
  isDesktop: boolean = false;
  width: number = 0;
  @Input() categoriesList: CategoryModel[];
  @Input() labels: CommonLabels;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId?: string;
  @Input() categoryFeaturedId?: string;
  @Input() categorySpecialId?: string;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() arrayApplicationFeatured?: any = [];
  @Input() dataApplication: ApplicationModel;
  @Input() disabledButton: boolean;
  @Input() showContactButton: boolean;
  @Input() showAddToLibraryButton: boolean;
  @Input() showAlreadyInLibraryButton: boolean;
  @Input() typeModal: string;

  @Output() emitAddToLibrary: EventEmitter<any> = new EventEmitter();
  @Output() emitContact: EventEmitter<any> = new EventEmitter();
  @Output() emitClickMore: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabList', { static: false }) tabList: MatTabGroup;

  isLoading: boolean = false;
  tabSelectedIndex: number = 0;
  lastVersion: number = 0;
  readme: string = '';
  showBarbara: boolean = false;
  showMostUsed: boolean = false;
  showFeatured: boolean = false;
  menuState: string = '';
  fakeTitle: string = '';
  fakeDescription: string = '';
  title: string;
  shortDescription: string;
  constructor(
    private resizeWindowService: ResizeWindowService,
    private readonly router: Router,
    private storageService: StorageService,
    private categoriesStoreService: CategoriesStoreService,
    public utilsService: UtilsService
  ) {}
  ngOnInit(): void {
    this.isLoading = true;

    this.resizeWindowService.isDesktop$.subscribe((value: boolean) => {
      this.isDesktop = value;
    });
    this.resizeWindowService.width$.subscribe((value: number) => {
      this.width = value;

      this.resizeWindowService.waitForFinalEvent(
        () => {
          this.width = value;
          if (this.dataApplication.versions.length > 0) {
            this.lastVersion = this.dataApplication.versions.length - 1;
            if (this.dataApplication.versions[this.lastVersion].readme !== '') {
              this.readme = this.utilsService.conversorString(
                this.dataApplication.versions[this.lastVersion].readme
              );
            }
          }
          this.dataApplication.categories.forEach((category) => {
            let _temp = this.categoriesList.find((cat) => cat._id === category._id);
            category.description = _temp.description;
            category.icon = _temp.icon;
            category.order = _temp.order;
            category.type = _temp.type;
            if (category.type === 1) {
              this.showFeatured = true;
            }
            if (category.type === 2) {
              this.setPurchasedApplication();
            }
            if (category.type === 3) {
              this.showMostUsed = true;
            }
            if (category.type === 4) {
              this.showBarbara = true;
            }
          });
          this.categoriesStoreService.getStateMenu().subscribe((state) => {
            this.menuState = state;
            this.title = this.dataApplication.name.replace(/<[^>]*>/g, '');
            this.shortDescription = this.dataApplication.shortDescription.replace(/<[^>]*>/g, '');
            if (this.width < 1279) {
              this.prepareData1024(this.dataApplication);
            }
            if (this.width >= 1279 && this.width < 1439) {
              this.prepareData1280(this.dataApplication);
            }
            if (this.width >= 1439 && this.width < 1679) {
              this.prepareData1440(this.dataApplication);
            }
            if (this.width >= 1679 && this.width < 1799) {
              this.prepareData1680(this.dataApplication);
            }
            if (this.width >= 1799 && this.width < 1900) {
              this.prepareData1800(this.dataApplication);
            }
            if (this.width >= 1900 && this.width < 2000) {
              this.prepareData1900(this.dataApplication);
            }
            if (this.width >= 2000) {
              this.prepareData2000(this.dataApplication);
            }
          });
        },
        10,
        'loadingAppDetail'
      );
    });
  }

  prepareData1024(data): void {
    if (data.shortDescription.length > 190 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 190) + ' ...';
    } else if (data.shortDescription.length > 240 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 240) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData1280(data): void {
    if (data.shortDescription.length > 220 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 220) + ' ...';
    } else if (data.shortDescription.length > 270 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 270) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData1440(data): void {
    if (data.shortDescription.length > 270 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 270) + ' ...';
    } else if (data.shortDescription.length > 320 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 320) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData1680(data): void {
    if (data.shortDescription.length > 330 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 330) + ' ...';
    } else if (data.shortDescription.length > 370 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 370) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData1800(data): void {
    if (data.shortDescription.length > 370 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 370) + ' ...';
    } else if (data.shortDescription.length > 400 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 400) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData1900(data): void {
    if (data.shortDescription.length > 480 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 480) + ' ...';
    } else if (data.shortDescription.length > 550 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 550) + ' ...';
    }
    this.isLoading = false;
  }
  prepareData2000(data): void {
    if (data.shortDescription.length > 1330 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 1330) + ' ...';
    } else if (data.shortDescription.length > 1390 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 1390) + ' ...';
    }
    this.isLoading = false;
  }
  setPurchasedApplication() {
    this.dataApplication.purchased = true;
  }
  addToLibrary(id: string, typeModal): void {
    this.emitAddToLibrary.emit({ id: id, typeModal: typeModal, width: this.width });
  }
  contact(id: string, typeModal): void {
    this.emitContact.emit({ id: id, typeModal: typeModal, width: this.width });
  }
  onClickMoreButton(id: string): void {
    this.emitClickMore.emit({ id: id, width: this.width });
  }
  selectedTabChange(event) {
    this.tabSelectedIndex = event.index;
  }
  identify(index, item) {
    return index;
  }
  ngOnDestroy(): void {}
}
