import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { AdminGuard } from './common/guards/admin.guard';
import { HomeComponent } from './layout/components/private/home/home.component';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'category-detail/:id',
    loadChildren: () => import('./main/category/category.module').then((m) => m.CategoryModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'application-detail/:id',
    loadChildren: () =>
      import('./main/application/application.module').then((m) => m.ApplicationModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
  // { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
