import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../services/snackMessage.service';
import { UtilsService } from '../../services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-card-vertical',
  templateUrl: './card-vertical.component.html',
  styleUrls: ['./card-vertical.component.scss']
})
export class CardVerticalComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() isDesktop: boolean;

  @Input() width: number;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() showText?: boolean;
  @Input() context: string;
  @Input() _data: any;

  @Output() moreButtonEvent = new EventEmitter<{ id: string; name: string; event: object }>();

  className = 'app-card-vertical';
  labels: CommonLabels = commonLabels;
  purchased: boolean = false;
  mostPurchased: boolean = false;
  special: boolean = false;
  featured: boolean = false;
  menuState: string = '';
  fakeTitle: string = '';
  fakeDescription: string = '';
  shortDescriptionTooltip: string;
  constructor(
    private snackMessageService: SnackMessageService,
    private categoriesStoreService: CategoriesStoreService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    //console.log(this._data);
    //console.log(this.categoryFeatured);
    this.categoriesStoreService.getStateMenu().subscribe(
      (state: string) => {
        this.menuState = state;
        //console.log(this.menuState);
        this.shortDescriptionTooltip = this._data.shortDescription.replace(/<[^>]*>/g, '');
        if (this.width < 1279) {
          this.prepareData1024(this._data);
        }
        if (this.width >= 1279 && this.width < 1439) {
          this.prepareData1280(this._data);
        }
        if (this.width >= 1439 && this.width < 1679) {
          this.prepareData1440(this._data);
        }
        if (this.width >= 1679 && this.width < 1799) {
          this.prepareData1680(this._data);
        }
        if (this.width >= 1799 && this.width < 1900) {
          this.prepareData1800(this._data);
        }
        if (this.width >= 1900 && this.width < 2000) {
          this.prepareData1900(this._data);
        }
      },
      (error) => {
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    // console.log(this.width);
    this.checkBadges(this._data);
  }
  checkBadges(data): void {
    data.categories.forEach((category) => {
      //console.log(category);
      if (this.context === '_home') {
        if (category.type === 1) {
          this.featured = true;
          this.categoryFeatured = category;
        }
        if (category.type === 2) {
          this.purchased = true;
          this.categoryPurchased = category;
        }
        if (category.type === 3) {
          this.mostPurchased = true;
          this.categoryMostPurchased = category;
        }

        if (category.type === 4) {
          this.special = true;
          this.categorySpecial = category;
        }
      } else {
        if (category === this.categoryPurchasedId) {
          this.purchased = true;
        }
        if (category === this.categoryMostPurchasedId) {
          this.mostPurchased = true;
        }
        if (category === this.categorySpecialId) {
          this.special = true;
        }
        if (category === this.categoryFeaturedId) {
          this.featured = true;
        }
      }
    });
  }
  prepareData1024(data): void {
    if (data.name.length > 48 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 48) + ' ...';
    } else if (data.name.length > 48 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 48) + ' ...';
    }
    if (data.shortDescription.length > 135 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 135) + ' ...';
    } else if (data.shortDescription.length > 135 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 135) + ' ...';
    }
  }
  prepareData1280(data): void {
    if (data.name.length > 47 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 47) + ' ...';
    } else if (data.name.length > 62 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 62) + ' ...';
    }
    if (data.shortDescription.length > 130 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 130) + ' ...';
    } else if (data.shortDescription.length > 165 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 165) + ' ...';
    }
  }
  prepareData1440(data): void {
    if (data.name.length > 62 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 62) + ' ...';
    } else if (data.name.length > 78 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 78) + ' ...';
    }
    if (data.shortDescription.length > 160 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 160) + ' ...';
    } else if (data.shortDescription.length > 200 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 200) + ' ...';
    }
  }
  prepareData1680(data): void {
    if (data.name.length > 80 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 80) + ' ...';
    } else if (data.name.length > 100 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 100) + ' ...';
    }
    if (data.shortDescription.length > 217 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 217) + ' ...';
    } else if (data.shortDescription.length > 249 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 250) + ' ...';
    }
  }
  prepareData1800(data): void {
    if (data.name.length > 62 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 62) + ' ...';
    } else if (data.name.length > 65 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 65) + ' ...';
    }
    if (data.shortDescription.length > 160 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 160) + ' ...';
    } else if (data.shortDescription.length > 185 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 185) + ' ...';
    }
  }
  prepareData1900(data): void {
    if (data.name.length > 65 && this.menuState === 'open') {
      this.fakeTitle = data.name.substring(0, 65) + ' ...';
    } else if (data.name.length > 78 && this.menuState === 'close') {
      this.fakeTitle = data.name.substring(0, 78) + ' ...';
    }
    if (data.shortDescription.length > 168 && this.menuState === 'open') {
      this.fakeDescription = data.shortDescription.substring(0, 168) + ' ...';
    } else if (data.shortDescription.length > 200 && this.menuState === 'close') {
      this.fakeDescription = data.shortDescription.substring(0, 200) + ' ...';
    }
  }
  clickButtonMore(id: string, name: string, event: object): void {
    this.moreButtonEvent.emit({ id, name, event });
  }
  ngOnDestroy(): void {}
}
